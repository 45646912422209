import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import ButtonSolid from "../../components/Button/ButtonSolid";

const AmenitiesSection1 = ({
  headingLevel,
  className,
  sectionTitle,
  sectionDesc,
}) => {
  return (
    <>
      <section className="bg-tertiary-50 relative mb-20 pt-12 md:mb-20 md:py-[2rem] xl:py-[8rem]">
        <div className="absolute bottom-0 left-0 hidden  md:block">
          <StaticImage
            src="../../images/5.0 Amenities/1.0 Amenities-Hero.png"
            loading="eager"
          />
        </div>
        <div className="container relative lg:py-24">
          <div className="grid gap-y-4 ">
            <div className="grid grid-cols-1 text-center md:max-w-[560px] md:gap-y-4  md:text-left">
              <h1 className="md:text-white">Amenities at HarborView</h1>
              <p className="md:text-white">
                We provide a wide variety of amenities designed to deliver
                compassionate care and enrich the lives of each of our
                residents.
              </p>
              <ButtonSolid
                class=""
                modal="hidden md:block"
                text="Contact Us"
                className={
                  "mx-auto md:ml-0 mr-auto max-w-[180px] h-[48px] text-[16px] font-normal lg:ml-0  "
                }
              />
            </div>
            <div className="-mx-4 md:mx-0 md:hidden">
              <StaticImage
                src="../../images/5.0 Amenities/1.0 Amenities-mobile.jpg"
                loading="eager"
                className="h-full w-full"
                height={350}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AmenitiesSection1;
