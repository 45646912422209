import React from "react";
import ButtonSolid from "../Button/ButtonSolid";
import ButtonGhost from "../Button/ButtonGhost";
import { StaticImage } from "gatsby-plugin-image";

const AmenitiesSection3 = ({
  headingLevel,
  className,
  sectionTitle,
  sectionDesc,
}) => {
  return (
    <>
      <section className="relative md:mb-20 md:pt-0">
        <div className="container">
          {/* START OF IMAGE AND IMAGE  */}
          <div className="md:flex-center flex-center flex grid sm:py-4 md:grid-cols-2  lg:gap-x-[50px]">
            <div className="relative -mx-4 md:mx-0 md:h-full">
              <div className="p-3 md:block">
                <StaticImage
                  className="rounded-4xl md:p-[15px]"
                  imgClassName=""
                  src="../../images/5.0 Amenities/2.0 residential.jpg"
                />
              </div>
            </div>
            <div className="relative -mx-4 md:mx-0 md:h-full">
              <div className="p-3 md:block">
                <StaticImage
                  className="rounded-4xl md:p-[15px]"
                  imgClassName=""
                  src="../../images/5.0 Amenities/2.0 deluxe.jpg"
                />
              </div>
            </div>
          </div>
          {/*END OF IMAGE AND IMAGE  */}
          {/* START OF IMAGE AND TEXT  */}
          <div className="md:flex-center flex-center flex grid sm:py-4 lg:grid-cols-2  lg:gap-x-[50px]">
            <div className="relative -mx-4 md:mx-0 md:h-full">
              <div className="h-full p-3 md:block">
                <StaticImage
                  className="h-full rounded-4xl md:p-[15px]"
                  objectFit="cover"
                  imgClassName="h-full"
                  src="../../images/5.0 Amenities/2.0 premium.jpg"
                />
              </div>
            </div>
            <div className="relative -mx-4 md:mx-0 md:h-full ">
              <div className="p-3 md:block md:py-8">
                <h2 className="mb-6">Accommodations</h2>
                <p className="">
                  Our rooms offer the comforts of home in an inviting
                  atmosphere. Each room is furnished, offering the amenities our
                  residents were accustomed to at home. We encourage our
                  residents and their families to decorate and personalize their
                  rooms as they wish.
                </p>
                <p className="mt-2">
                  It has been said that HarborView accommodations offer the
                  refinement of a boutique hotel, with easy access to the rest
                  of the home. Many of our rooms offer seating areas and views
                  of the garden or San Diego Bay and downtown.
                </p>
                <p className="mt-2">
                  Some rooms have attached private bathrooms and areas for
                  sitting, reading, quilting, or resting. You can choose from
                  rooms with magnificent views of San Diego Bay and the downtown
                  cityscape. These rooms offer added privacy, en suite
                  bathrooms, and private seating areas. These more spacious
                  rooms are great for singles but can comfortably accommodate
                  couples, with high ceilings, original fixtures, and timeless
                  decor.
                </p>
                <div className="flex gap-x-4 ">
                  <div className="col-span-3 col-start-1 block">
                    <ButtonSolid
                      className=""
                      modal="modal-contact"
                      text="Contact"
                    />
                  </div>
                  <div className=" col-span-3 col-start-6 block md:col-start-4">
                    <ButtonGhost
                      href="tel:(619) 233-8382"
                      text={"(619) 233-8382"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*END OF IMAGE AND TEXT  */}
        </div>
      </section>
    </>
  );
};

export default AmenitiesSection3;
