import React from "react";

// import { StaticImage } from "gatsby-plugin-image";

const AmenitiesSection2 = ({
  headingLevel,
  className,
  sectionTitle,
  sectionDesc,
  sectionContentArray,
}) => {
  return (
    <>
      <section className="">
        <div className="container">
          <h2 className="px-3">What We Offer</h2>
          {/* START OF IMAGE AND TEXT  */}
          <div className="md:flex-center flex-center flex grid py-4 md:grid-cols-2  md:gap-x-[50px] md:gap-y-12">
            <div className="relative -mx-4 md:mx-0 md:h-full">
              <div className="p-3 md:block">
                <ul className="grid gap-y-3 md:gap-y-[5px]">
                  <li className="flex py-[5px]">
                    <svg
                      class="mr-2 h-6 w-6 flex-none fill-[#822824] stroke-[#F8F2F2] stroke-2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="11" />
                      <path
                        d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                        fill="none"
                      />
                    </svg>
                    <p>Rooms that accommodate partners</p>
                  </li>
                  <li className="flex py-[5px]">
                    <svg
                      class="mr-2 h-6 w-6 flex-none fill-[#822824] stroke-[#F8F2F2] stroke-2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="11" />
                      <path
                        d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                        fill="none"
                      />
                    </svg>
                    <p>Personal laundry service and daily housekeeping</p>
                  </li>
                  <li className="flex py-[5px]">
                    <svg
                      class="mr-2 h-6 w-6 flex-none fill-[#822824] stroke-[#F8F2F2] stroke-2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="11" />
                      <path
                        d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                        fill="none"
                      />
                    </svg>
                    <p>Secured perimeter environment with monitored access</p>
                  </li>
                  <li className="flex py-[5px]">
                    <svg
                      class="mr-2 h-6 w-6 flex-none fill-[#822824] stroke-[#F8F2F2] stroke-2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="11" />
                      <path
                        d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                        fill="none"
                      />
                    </svg>
                    <p>Hospice accommodations</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="relative -mx-4 md:mx-0 md:h-full">
              <div className="p-3 md:block">
                <ul className="grid gap-y-3 md:gap-y-[5px]">
                  <li className="flex py-[5px]">
                    <svg
                      class="mr-2 h-6 w-6 flex-none fill-[#822824] stroke-[#F8F2F2] stroke-2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="11" />
                      <path
                        d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                        fill="none"
                      />
                    </svg>
                    <p>
                      Trained staff available 24/7 to aid with dressing,
                      bathing, navigating, medication management, and
                      emergency/medical situations
                    </p>
                  </li>
                  <li className="flex py-[5px]">
                    <svg
                      class="mr-2 h-6 w-6 flex-none fill-[#822824] stroke-[#F8F2F2] stroke-2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="11" />
                      <path
                        d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                        fill="none"
                      />
                    </svg>
                    <p>
                      Daily activities that foster physical fitness, creativity,
                      socialization, learning, and spirituality
                    </p>
                  </li>
                  <li className="flex py-[5px]">
                    <svg
                      class="mr-2 h-6 w-6 flex-none fill-[#822824] stroke-[#F8F2F2] stroke-2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="11" />
                      <path
                        d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                        fill="none"
                      />
                    </svg>
                    <p>
                      Gracious accommodations with a variety of unique floor
                      plans and architecture
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*END OF IMAGE AND TEXT  */}

        </div>
      </section>
    </>
  );
};

export default AmenitiesSection2;
